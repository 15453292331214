var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"menuContent"},[_c('div',{staticClass:"menuMain"},[_c('router-link',{staticClass:"menuTitle",attrs:{"to":"/"}},[_vm._v("LaunchInfo")]),_c('div',{staticClass:"menuToggle",class:{
                    toggleActive: _vm.menuToggled
                },on:{"click":function($event){_vm.menuToggled = !_vm.menuToggled}}},[_c('div',{staticClass:"menuLine"}),_c('div',{staticClass:"menuLine"}),_c('div',{staticClass:"menuLine"})])],1),_c('div',{staticClass:"menuLinks",class:{ showLinks: _vm.menuToggled },on:{"click":function($event){_vm.menuToggled = false}}},[_c('router-link',{staticClass:"menuButton",class:{ highlighted: this.$route.name == 'Agencies' },attrs:{"to":"/agencies"}},[_vm._v("Agencies")]),_c('router-link',{staticClass:"menuButton",class:{ highlighted: this.$route.name == 'Rockets' },attrs:{"to":"/rockets"}},[_vm._v("Rockets")]),_c('router-link',{staticClass:"menuButton",class:{
                    highlighted:
                        this.$route.name == null ||
                        this.$route.name == 'Launches landing' ||
                        this.$route.name == 'Launches'
                },attrs:{"to":"/launches"}},[_vm._v("Launches")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }